@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap);
.main-container > header {
  height: 10rem;
}

header > img {
  width: 100%;
  height: inherit;
}

header > h1 {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: #121b83;
  text-transform: uppercase;
  text-shadow: 3px 2.5px #7284db;
  letter-spacing: 4px;
}

.left-side-bar > section {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 1rem;
}

#counter {
  background: #000000;
  width: 10rem;
  padding: 0 0.3rem;
  font-size: 2rem;
  font-weight: bold;
  color: #669998;
  text-align: right;
  float: right;
}

main {
  padding: 0 1rem;
}

main > h2,
.home-container > h2 {
  color: #1a3697;
  font-weight: bold;
  text-align: center;
}

main h3,
.home-container > h3 {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: left;
}

main > p,
.home-container > p {
  text-align: center;
}

.home-container > footer {
  font-size: 0.8rem;
  text-align: center;
}

.comments-container {
  padding: 1rem 0;
  width: 100%;
}

article.comment {
  padding: 1rem 0;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment > header,
.comment > footer {
  font-size: 0.7rem;
}

main img {
  max-width: 100%;
  margin: 0.5rem 0;
}

.poster,
.portrait {
  max-height: 400px;
  cursor: pointer;
  transition: 0.3s;
}

.poster:hover,
.portrait:hover {
  opacity: 0.7;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding: 5% 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  max-height: 90%;
  max-width: 90%;
}

.modal-content,
.caption {
  animation-name: zoom;
  animation-duration: 0.3s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 3rem;
  color: #f1f1f1;
  font-size: 3rem;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .modal-content {
    width: 100vw;
  }
}

.left-side-bar {
  background-color: #d5e6fa;
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

nav > a,
nav > a:link,
nav > a:visited,
nav > a:hover,
nav > a:active {
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 1rem;
}

nav > a.blue {
  color: #1a3697;
}

nav > a.black {
  color: #000000;
}

.main-container > footer {
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px; /*rem*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  justify-content: center;
}

/* Grid Layout */
.main-container {
  height: 100vh;
  width: 800px;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 33% 66%;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.main-container > header {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: span 2;
}

.main-container > div.left-side-nav {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

.main-container > main {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}

.main-container > footer {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: span 2;
}

/* Mobile layout */
@media only screen and (max-width: 800px) {
  .main-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .main-container > .left-side-bar {
    display: flex;
    flex-direction: column;
  }

  .left-side-bar > nav {
    display: flex;
    flex-direction: row;
    /* padding-top: ; */
  }

  nav > a,
  nav > a:link,
  nav > a:visited,
  nav > a:hover,
  nav > a:active {
    padding: 0;
  }

  .left-side-bar > section {
    padding-top: 0;
  }
}

h1 {
  font-family: "Permanent Marker", cursive;
}

nav > a {
  transform: scaleY(0.9);
  font-weight: bold;
}

h3 {
  font-family: "Playfair Display", serif;
  font-weight: bold;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

