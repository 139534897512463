.left-side-bar > section {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 1rem;
}

#counter {
  background: #000000;
  width: 10rem;
  padding: 0 0.3rem;
  font-size: 2rem;
  font-weight: bold;
  color: #669998;
  text-align: right;
  float: right;
}
