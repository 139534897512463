.left-side-bar {
  background-color: #d5e6fa;
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

nav > a,
nav > a:link,
nav > a:visited,
nav > a:hover,
nav > a:active {
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 1rem;
}

nav > a.blue {
  color: #1a3697;
}

nav > a.black {
  color: #000000;
}
