.main-container > header {
  height: 10rem;
}

header > img {
  width: 100%;
  height: inherit;
}

header > h1 {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: #121b83;
  text-transform: uppercase;
  text-shadow: 3px 2.5px #7284db;
  letter-spacing: 4px;
}
