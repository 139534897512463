@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");

h1 {
  font-family: "Permanent Marker", cursive;
}

nav > a {
  transform: scaleY(0.9);
  font-weight: bold;
}

h3 {
  font-family: "Playfair Display", serif;
  font-weight: bold;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
