main {
  padding: 0 1rem;
}

main > h2,
.home-container > h2 {
  color: #1a3697;
  font-weight: bold;
  text-align: center;
}

main h3,
.home-container > h3 {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: left;
}

main > p,
.home-container > p {
  text-align: center;
}

.home-container > footer {
  font-size: 0.8rem;
  text-align: center;
}

.comments-container {
  padding: 1rem 0;
  width: 100%;
}

article.comment {
  padding: 1rem 0;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment > header,
.comment > footer {
  font-size: 0.7rem;
}

main img {
  max-width: 100%;
  margin: 0.5rem 0;
}

.poster,
.portrait {
  max-height: 400px;
  cursor: pointer;
  transition: 0.3s;
}

.poster:hover,
.portrait:hover {
  opacity: 0.7;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding: 5% 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  max-height: 90%;
  max-width: 90%;
}

.modal-content,
.caption {
  animation-name: zoom;
  animation-duration: 0.3s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 3rem;
  color: #f1f1f1;
  font-size: 3rem;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .modal-content {
    width: 100vw;
  }
}
