#root {
  display: flex;
  justify-content: center;
}

/* Grid Layout */
.main-container {
  height: 100vh;
  width: 800px;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 33% 66%;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.main-container > header {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: span 2;
}

.main-container > div.left-side-nav {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

.main-container > main {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}

.main-container > footer {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: span 2;
}

/* Mobile layout */
@media only screen and (max-width: 800px) {
  .main-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .main-container > .left-side-bar {
    display: flex;
    flex-direction: column;
  }

  .left-side-bar > nav {
    display: flex;
    flex-direction: row;
    /* padding-top: ; */
  }

  nav > a,
  nav > a:link,
  nav > a:visited,
  nav > a:hover,
  nav > a:active {
    padding: 0;
  }

  .left-side-bar > section {
    padding-top: 0;
  }
}
